<template>
  <!-- 版本记录 组件 -->
  <div class="contains">
    <Timeline>
      <TimelineItem v-for="(item,index) in taskInfo.filter(item=>item.commentObj)" :key="index">
        <div class="title flex">
          <p class="m-r-md">{{item.commentObj.nodeName}}</p>
          <p class="m-r-md">{{item.commentObj.userName}}</p>
          <p>{{item.time}}</p>
        </div>
        <div class="chunk">
          <p class="chunk_top_other" v-if="item.commentObj.pass!=0">{{item.commentObj.comment||'无'}}</p>
          <p class="chunk_top" v-else>{{item.commentObj.comment||'无备注'}}</p>
          <!-- <div class="content_right_other">
            <span
              class="chunk_base"
            >{{item.commentObj.userName}}&nbsp;&nbsp;&nbsp;{{item.time}}&nbsp;&nbsp;&nbsp;版本：{{item.commentObj.version}}</span>
            <Icon class="content_right_button" type="md-checkmark-circle" color="green" />
            <a @click="getViewHistory(item.commentObj.version)">点击查看</a>
          </div>-->
        </div>
      </TimelineItem>
    </Timeline>
  </div>
</template>

<script >
import axios from "../../../api/newAxiosProxy";
export default {
  props: ["taskInfo"],
  data() {
    return {
      taskInfoList: []
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {
    taskInfo: {
      handler() {
        if (!this.taskInfo) {
          return;
        }

        for (let v of this.taskInfo) {
          // 后台返回的是字符串，这里需要转换一下！
          v.commentObj = v.comment ? v.comment : undefined;
        }
      },
      immediate: true
    }
  },
  methods: {
    getViewHistory(e) {
      let contentType = this.$route.query.contentType;
      let id = this.$route.query.bizId;
      axios(
        {
          url: "/common/content/viewHistory",
          method: "get",
          params: { contentType, id, version: e }
        },
        true
      ).then(res => {
        this.$emit("hbData", res.mdContentList);
      });
    }
  },
  components: {}
};
</script>

<style scoped>
.contains {
  padding: 30px;
}
.content {
  display: flex;
  display: -webkit-flex;
}
.ivu-steps {
  width: auto;
}
.content_right {
  margin-top: 8px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}
.content_right_other {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  background: #00e7ff;
}
.title {
  font-weight: bold;
}
.content_right_button {
  margin-left: 15px;
}
/* chunk start */
.chunk {
  height: 70px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px 10px 0 0;
  border: 1px solid gainsboro;
  width: 450px;
}
.chunk_top {
  padding: 5px 0 5px 20px;
}
.chunk_top_other {
  padding: 5px 0 5px 20px;
  color: red;
}
.chunk_base {
  padding: 0 20px;
}
.iconType {
  width: 13px;
  height: 13px;
  background: #00e7ff;
  border-radius: 50%;
  position: relative;
  left: 13px;
}
</style>
